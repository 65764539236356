 const PURCHASE_PRODUCT = 'PURCHASE_PRODUCT';
 const SHARE_EXPERIENCE = 'SHARE_EXPERIENCE';
 const PAGE_VISIT = 'PAGE_VISIT';
 const VIEW_SCENE = 'VIEW_SCENE';
 const TIME_SPENT = 'TIME_SPENT';

export const ANALYTICS_EVENT_TYPE = {
    PURCHASE_PRODUCT,
    SHARE_EXPERIENCE,
    PAGE_VISIT,
    VIEW_SCENE,
    TIME_SPENT
};
