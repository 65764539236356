import React from 'react'

export default function Otra360VR({dataOtra, Xpos, fondo, modo }) {
    const {imagen, titulo, url} = dataOtra
    


  const fondoSky = dataOtra.otraExperiencia? dataOtra.otraExperiencia : fondo

  return(
    
    <a-entity id="portals" mixin="hoverAnimation" position={`${Xpos} 1.1 -6`}    
    
    >
      <a-entity 
       class="portal"
       mixin="portal"
       //material={`color: hsl(${(Xpos * 60) % 360}, 70%, 50%); opacity: 0.3`}
       material={`color: hsl(0, 0%, ${(Xpos * 10) + 50}%); opacity: 0.3`}
       
       >
      <a-sky
        radius="1"
        class="interactable"
        navigate-out={url}
       // material={`shader: flat; src: ${fondo}; opacity: 1`}
       material={`shader: flat; src: ${modo? null : fondoSky}; opacity: 1`}
       geometry="primitive: sphere"
        animation="property: rotation; to: 0 360 0; dur: 10000; easing: linear; loop: true"
      ></a-sky>
        {/* <a-image
           //class="portalEffect1"
           class="interactable"
           navigate-out={url}
            geometry="primitive: circle"
            material={`shader: flat; src: ${modo? null : fondo}; opacity: 1`}
            //scale="3 3 3"
          //  animation="property: object3D.rotation.y; from: 0; to: 360; dur: 10000; loop: true; easing: linear"
          ></a-image> */}
        
         <a-image
          class="portalEffect1"              
          geometry="primitive: circle; radius: 1.2"
          material="opacity: 0.6; depthWrite: false; shader: flat; transparent: true; src: #portalImg"              
          position="0 0 0"              
          animation="property: object3D.rotation.z; from: 0; to: 360; dur: 3000; loop: true; easing: linear"
        ></a-image>
       
       <a-plane 
          width="2.5"
          height="0.4"
          material="color: #000000; opacity: 0.3"
          position="0 1.5 0"
          >
        <a-entity
          class="portalText"
          // mixin="slice font"
          // slice9="color:  #111; height: 0.3; width: 2.5; opacity: 0.7"          
          text={`color: #FFFF; align: center; value: ${titulo}; side: double; width: 5; zOffset: 0.01`}
          //position="0 1.3 0"
        ></a-entity>
      </a-plane>

      </a-entity>
    </a-entity>
  )

  // return (
  //   <a-entity 
  //   //position="0 1.1 -1"
  //   position={`${Xpos} 1.1 -1`}
  //   animation="property: rotation; to: 0 360 0; dur: 10000; easing: linear; loop: true"
  // >
  //   <a-box
  //     class="interactable"
  //     width="0.2"
  //     height="0.2"
  //     depth="0.2"
  //     material={`shader: standard; color: white; opacity: 0.5; transparent: true; src: ${imagen}`}     
  //     navigate-out={url}      
      
  //   >
  //       <a-text
  //           value={titulo || "Experiencia"}
  //           position="0 0.15 0.12"
  //           width="1"
  //           align="center"
  //           color="white"
  //         ></a-text>
  //         <a-text
  //           value={titulo || "Experiencia"}
  //           position="0 0.15 -0.1"
  //           rotation="0 180 0"
  //           width="1"
  //           align="center"
  //           color="white"
  //         ></a-text>
  //   </a-box>
  // </a-entity>
  // )
}
